<template>
	<div class="d-flex flex-column-fluid">
		<!--begin::Container-->
		<div class="container">
			<!--begin::Card-->
			<div class="card card-custom card-transparent">
				<div class="card-body p-0">
					<!--begin::Wizard-->
					<div class="wizard wizard-4" id="kt_wizard">
						<!--begin::Card-->
						<div class="card card-custom card-shadowless rounded-top-0">
							<!--begin::Body-->
							<div class="card-body p-0">
								<div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
									<div class="col-xl-12 col-xxl-10">
										<!--begin::Wizard Form-->
										<b-form class="form" id="kt_form" novalidate @submit.prevent="submit">
											<div class="row justify-content-center">
												<div class="col-xl-9">
													<!--begin::Wizard Step 1-->
													<div class="my-5 step">
														<h5 class="text-dark font-weight-bold mb-10">New Site Profile:</h5>
														<!--begin::Group-->
														<div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label">Name</label>
															<div class="col-lg-9 col-xl-9">
																<b-form-input
																	class="form-control form-control-solid form-control-lg"
																	name="firstname"
																	type="text"
																	placeholder="Name"
																	v-model="form.name"
																	:state="nameValidation"
																	@blur="touched.name = true"
																/>
																<b-form-invalid-feedback id="input-live-feedback">
																	Name is required!
																</b-form-invalid-feedback>
																<!-- <span v-show="invalid" class="form-text hasError">Name is required!</span> -->
															</div>
														</div>
														<!--end::Group-->
														<!--begin::Group-->
														<div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label">Icon</label>
															<div class="col-lg-9 col-xl-9">
																<div class="input-group input-group-solid input-group-lg">
																	<select class="form-control form-control-solid" v-model="form.icon">
																		<option v-for="(icon, index) in icons" :key="icon + index" :value="icon">{{ icon }}</option>
																	</select>
																</div>
															</div>
														</div>
														<div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label"></label>
															<div class="col-lg-9 col-xl-9">
																<img width="50" :src="'/media/img/ICON-' + (form.icon ? form.icon : 'dish') + '.png'" />
															</div>
														</div>
														<!--end::Group-->
													</div>
													<!--end::Wizard Step 1-->
													<div class="d-flex justify-content-between border-top pt-10 mt-15">
														<div class="mr-2">
															<button type="button" id="prev-step" class="btn btn-light font-weight-bolder px-9 py-4" @click.prevent="cancel()">
																Cancel
															</button>
														</div>
														<div>
															<button
																:disabled="!nameValidation"
																:class="{ invalid: nameValidation }"
																type="Submit"
																class="btn btn-success font-weight-bolder px-9 py-4"
															>
																Submit
															</button>
															<!-- <button type="button" id="next-step" class="btn btn-primary font-weight-bolder px-9 py-4" data-wizard-type="action-next">Next</button> -->
														</div>
													</div>
													<!--end::Wizard Actions-->
													<div v-if="success" class="alert alert-success alert-msg">
														<strong>Success! </strong> Redirecting to the new site profile...
													</div>
													<div v-if="error" class="alert alert-danger alert-msg"><strong>Error!</strong> {{ error }}</div>
												</div>
											</div>
										</b-form>
										<!--end::Wizard Form-->
									</div>
								</div>
							</div>
							<!--end::Body-->
						</div>
						<!--end::Card-->
					</div>
					<!--end::Wizard-->
				</div>
			</div>
			<!--end::Card-->
		</div>
		<!--end::Container-->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
	data() {
		return {
			loaded: false,
			icons: ['dish', 'oilrig', 'ship', 'yacht', 'cruiseship', 'mine', 'camp', 'office', 'airplane', 'aid', 'cloud', 'datacenter'],
			form: {
				name: '',
				icon: 'dish',
			},
			success: false,
			touched: {
				name: false,
			},
			error: '',
		};
	},
	computed: {
		...mapGetters(['currentUser']),
		nameValidation() {
			if (!this.touched.name) {
				return null;
			} else {
				return this.form.name.length > 1;
			}
		},
	},
	methods: {
		cancel() {
			this.$router.push('/admin');
		},
		submit() {
			this.$http
				.post('adminsite', { data: this.form })
				.then(res => {
					if (res.data.data.SiteID != null) {
						this.success = true;
						setTimeout(() => {
							this.$router.push(`/admineditsite/${res.data.data.SiteID}`);
							this.success = false;
						}, 3000);
					} else {
						this.error = res.status + ' ' + res.statusText;
					}
				})
				.catch(err => {
					this.error = err;
					console.log('error:', err.response);
				});
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Admin New Site' }]);
	},
};
</script>

<style scoped>
.container {
	font-size: 1.1rem;
}
.hasError {
	color: red;
}
.invalid {
	cursor: not-allowed;
}
.alert-msg {
	text-align: center;
	padding: 20px;
	margin: 10px 0;
	border-radius: 15px;
	border-radius: 5px;
	font-weight: 700;
	font-size: 1.1rem;
}
</style>
